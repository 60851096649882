import { AccessTokenModel } from "./accessTokenModel";

export class LoginModel  {
    userName: string = "";
    password: string = "";
    isAuthenticated : boolean = false;
    accessToken? : AccessTokenModel;
    lock: boolean = false;
    attempts: number = 0;
    refreshToken: string = "";
}