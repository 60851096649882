import { useState } from "react";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid"
import Tabs from "../components/tabs";
import Input from "../components/input";
import Button from "../components/button";
import Form from "../components/form";
import accountSettingsValidation from "../validations/accountSettingsValidation";

function AccountSettings(){
const tabs = ['Change Email','Change Password'] 
const [selectedTab,setSelectedTab] = useState(tabs[0])

  const [password, setPassword] = useState({
    current: "",
    new: "",
    confirm: ""
  });
  const [email, setEmail] = useState({
    current: "",
    new: "",
    confirm: ""
  });
  const [pwErrors, setPwErrors] = useState({
    new: {show: false, message: ""},
    confirm: {show: false, message: ""},
    current: {show: false, message: ""}
  });
  const [emailErrors, setEmailErrors] = useState({
    new: {show: false, message: ""},
    confirm: {show: false, message: ""},
    current: {show: false, message: ""}
  });
  
  const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({
      ...email,
      [e.target.name]: e.target.value
    });
  }
  const onPasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value
    });
  }  

  const emailSubmit = () => {
    let errTemp = {
      new: {show: false, message: ""},
      confirm: {show: false, message: ""},
      current: {show: false, message: ""}
      } as any
      accountSettingsValidation.email.validate(email, {abortEarly:false})
      .then(() => {
        setEmailErrors(errTemp);
        //make request to update email here
      })
      .catch((errResponse) => {
        errResponse.inner.forEach((error: any) => {
          errTemp[error.path].message = error.message
          errTemp[error.path].show = true
        });
        setEmailErrors(errTemp);
      })
  }
  const passwordSubmit = () => {
    let errTemp = {
      new: {show: false, message: ""},
      confirm: {show: false, message: ""},
      current: {show: false, message: ""}
      } as any
    accountSettingsValidation.password.validate(password, {abortEarly:false})
      .then(() => {
        setPwErrors(errTemp);
        //make request to update pw here
      })
      .catch((errResponse) => {
        errResponse.inner.forEach((error: any) => {
          errTemp[error.path].message = error.message
          errTemp[error.path].show = true
        });
        setPwErrors(errTemp);
      })
  }

  const onClickTabs = (tabName:string) => {
    setSelectedTab(tabName);
  } 

    return (
        <div className="h-screen bg-gray-50 caret-transparent">
           <div className="bg-gray-50 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
           <div className="mx-auto max-w-3xl">
          <div className="flex min-h-screen  flex-1 flex-col py-12 sm:px-6 lg:px-8">
          <div className="h-16 pb-3 mt-6 sm:mx-auto sm:w-full sm:max-w-2xl grid grid-cols-4 gap-2">
            <div className="flex justify-start items-center col-span-3">
              {/* ADD AVATAR HERE */}
            <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-primary">
                <span className="text-sm font-medium leading-none text-white">AH</span>
              </span>

            <div className="mx-4 text-gray-900 text-xl font-bold leading-normal">
              <p>Andre Ho’s</p>
              <p>Account Settings</p>
            </div>
            </div>
        </div>
        <Tabs onChange={(tabName) => onClickTabs(tabName)} onClick={(tabName) => onClickTabs(tabName)} data={tabs}></Tabs>

           {selectedTab === "Change Email" && <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-2xl">
              <div className="bg-white px-6 py-6 shadow sm:rounded-lg ">
                <Form onSubmit={emailSubmit}>
                  <h1 className="font-bold text-xl">Change Email</h1>
                  <Input onChange={onEmailInputChange} name="current" label="Current email address" placeholder="Enter your current email address" 
                    id="emailCurrent" required={true} type="email" error={emailErrors.current.show} errorMessage={emailErrors.current.message}/>
                  <Input onChange={onEmailInputChange} name="new" label="New email address" placeholder="Enter your new email address" 
                    id="emailNew" required={true} type="email" error={emailErrors.new.show} errorMessage={emailErrors.new.message}/>
                  <Input onChange={onEmailInputChange} name="confirm" label="Confirm email address" placeholder="Confirm email address" 
                    id="emailConfirm" required={true} type="email" error={emailErrors.confirm.show} errorMessage={emailErrors.confirm.message}/>
                  <Button type="submit" onClick={() => {}} text="Save Changes"/>
                </Form>
              </div>
            </div> }

            {selectedTab === "Change Password" &&    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-2xl">
              <div className="bg-white px-6 py-6 shadow sm:rounded-lg ">
                <Form onSubmit={passwordSubmit}>
                  <h1 className="font-bold text-xl">Change Password</h1>
                  <Input type="password" id="currentPW" name="current" 
                    label="Current password" required={true} onChange={onPasswordInputChange}
                    error={pwErrors.current.show} errorMessage={pwErrors.current.message} placeholder="Enter your current password"/>
                  <Input type="password" id="newPW" name="new" 
                    label="New password" required={true} onChange={onPasswordInputChange}
                    error={pwErrors.new.show} errorMessage={pwErrors.new.message} placeholder="Enter your new password"/>
                  <Input type="password" id="confirmPW" name="confirm" 
                    label="Confirm password" required={true} onChange={onPasswordInputChange}
                    error={pwErrors.confirm.show} errorMessage={pwErrors.confirm.message} placeholder="Confirm your new password"/>
                  <div className=' text-sm font-medium leading-6'>
                    <label className="block text-gray-900">Password requirements:</label>
                    <label className="block text-gray-500">Ensure that these requirements are met:</label>
                  </div>

                  <div className='text-xs font-small leading-6  text-gray-500'>
                    <label className='block'>At least 10 characters (and up to 100 characters)</label>
                    <label className='block'>At least one lowercase character</label>
                    <label className='block'>At least one uppercase character</label>
                    <label className='block'>Include at least one special character, e.g., ! @ # ?</label>
                  </div>
                  <Button type="submit" onClick={() => {}} text="Save changes"/>
                </Form>
              </div>
            </div> }
          </div>
          </div>
          </div>
          </div>
      )
}

export default AccountSettings;