import { useNavigate } from 'react-router-dom';
import ProjectCard from './ProjectCard'

interface Card {
    name: string,
    description?: string,
    href: string,
    icon: any
}

interface Cardling {
    name: string,
    href: string,
    icon: any
}

interface Props {
    cards: Card[],
    cardlings?: Cardling[]
}

export default function Project(props: Props) {

const navigate = useNavigate();
  
const navigateToPorject = (herf:string) =>{
  navigate(herf);
} 
  return (
    <div className='mx-auto content-center flex  max-w-screen-2xl gap-5 px-6 py-6 justify-center flex-wrap'>
      {props.cards.map((item) => (
        <ProjectCard key={item.name} cardDetail={item} onClick={(herf) => navigateToPorject(herf)}/>
      ))}
    </div>
  )
}