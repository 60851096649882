import * as Yup from "yup";

export const updatePasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required")
    .min(10, 'Password must be at least 10 characters')
    .max(100, 'Password must be at most 100 characters')
    .matches(/[A-Z]/,"Password must contain at least one upper case letter")
    .matches(/[a-z]/,"Password must contain at least one lower case letter")
    .matches(/\W/,"Password must contain at least one special character")
})
