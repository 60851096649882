
interface Props {
    onSubmit?: any,
    children?: any,
    className?: any,
}

export default function Form (props: Props) {
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props?.onSubmit();
    }

    return (
        <form className={`${props?.className ?? "space-y-6"}`} noValidate onSubmit={onSubmit}>
            {props?.children}
        </form>
        // <form className="space-y-6" noValidate onSubmit={onSubmit}>
        //     {props?.children}
        // </form>
    )
}














































