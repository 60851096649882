import { FaSpinner, FaCheck, FaExclamation } from 'react-icons/fa';

interface Props {
    text?: any,
    className?:string
    onClick?: any,
    disabled?:boolean,
    type?: any,
    loading?: boolean,
}

function Button(props?:Props) {

  let buttonClassName = `${props?.className} justify-center rounded-xl px-3 m-1 py-2.5 font-semibold shadow-sm hover:bg-${props?.type}Hover`;
  switch (props?.type) {
      case 'primary':
          buttonClassName += ` bg-primary text-white`;
          break;
      case 'secondary':
          buttonClassName += ` bg-secondary text-black`;
          break;
      case 'tertiary':
          buttonClassName += ` bg-tertiary text-black`;
          break;
      default:
          buttonClassName += ` bg-primary`;
          break;
  }

return (
    <button disabled={props?.disabled ?? false}
    className={buttonClassName} 
    onClick={()=> props?.onClick()}
    type={props?.type}>
      {!props?.loading && props?.text}
      {props?.loading && <FaSpinner className="spinner-icon" />}
      {/* {!props?.disabled && (props?.type === 'primary' ? <FaCheck className="success-icon" /> : <FaExclamation className="error-icon" />)} */}
    </button>
);

}
export default Button;