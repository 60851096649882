import Cookies from 'universal-cookie';
import { LoginModel } from "../../model/loginModel"
import BaseService from '../baseService';
import { RoleModel } from '../../model/roleModel';
import { UserModel } from '../../model/userModel';

/**
 * The URL used for authentication with the API.
 *
 * @type {string}
 */
const authUrl = process.env.REACT_APP_API_URL;
/**
 * Class representing cookies.
 * @class
 */
const cookies = new Cookies();
/**
 * Authenticate user and sign in.
 *
 * @param {LoginModel} params - The login parameters.
 * @returns {Promise<boolean | string>} - A promise that resolves to a boolean value or an error message.
 */
const signIn = async (params:LoginModel) => {
  return await BaseService.postAnonymous(authUrl + "/authentication/authenticate",params).then(res => {
              if(res && res.status === 200)
              {
                  cookies.remove("uinfo");
                  cookies.remove("ainfo");
                  cookies.set("uinfo",res.data);
                  return true;
              }
              else{
                  cookies.remove("uinfo");
                  cookies.remove("ainfo");
                  return false;
              }
            }).catch((error) => {
              cookies.remove("uinfo");
              cookies.remove("ainfo");
              if(error?.response?.data){
                  return error?.response?.data;
              }
              return false;
            });
            
        }

  
  /**
   * Performs a test authentication request.
   *
   * @async
   * @function test
   * @returns {Promise<object>} - The authentication response data.
   * @throws {Error} - If the request fails or an error occurs.
   */
  const test = async () => {
    return await BaseService.post(authUrl + "/authentication/test",{}).then(res => {
       return res.data;
    })
  }

    const getAllUsers = async (currentPage: number, pageSize: number, searchText?: string) => {
      return await BaseService.get(authUrl + `/user/users?currentPage=${currentPage}&pageSize=${pageSize}${searchText? `&searchText=${searchText}` : ""}`).then(res => {
         return res.data;
      })
    }

    const getAllRoles = async () => {
      return await BaseService.get(authUrl + "/user/roles").then(res => {
       var keys = Object.keys(res.data);
       let model:RoleModel[] = []; 
       for(var i = 0; i < keys.length; i++){
        model.push(new RoleModel(keys[i], res.data[`${keys[i]}`]) 
          )
       }
       keys.forEach(x => x)
         return model;
      })
    }

      const createUser = async (payload:UserModel) => {
        return await BaseService.post(authUrl + "/user",payload).then(res => {
         return res.data;
        })
      }

      const editUser = async (payload:UserModel) => {
        return await BaseService.post(authUrl + "/user/update",payload).then(res => {
         //console.log("editUser", res)
         return res.data;
        })
      }

/**
 * Represents a UserService object that provides authentication and testing functionality.
 *
 * @namespace
 */
const UserService = {
    signIn,
    test,
    getAllUsers,
    getAllRoles,
    createUser,
    editUser
}


 export default UserService;


