import { useLoaderData, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { decrement, increment,incrementByAmount } from '../redux/counterSlice'
import Project from "../components/projectCard/project";
import { ComputerDesktopIcon, RectangleGroupIcon, ChartPieIcon, CloudIcon, MegaphoneIcon, ChatBubbleLeftRightIcon, Cog8ToothIcon } from "@heroicons/react/24/solid";
function Home(props: any) {

    const cards = [{
        name: "POS",
        href: "",
        icon: ComputerDesktopIcon
    },{
        name: "POS Management",
        href: "",
        icon:RectangleGroupIcon
    },{
        name: "Analytics",
        href: "",
        icon:ChartPieIcon
    },{
        name: "Marketing Dashboard",
        href: "",
        icon:CloudIcon
    },{
        name: "Help Desk",
        href: "",
        icon:MegaphoneIcon
    },{
        name: "Chat",
        href: "",
        icon:ChatBubbleLeftRightIcon
    },
    {
        name: "User Management",
        href: "userManagement",
        icon:Cog8ToothIcon
    }]


   // const App = useAppSelector((state) => state.claims)
    
    return (<Project cards={cards}/>);
}

export default Home;


  