import { LoginModel } from "../model/loginModel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../GravityLogoLg.svg";
import Button from "../components/button";
import Input from "../components/input";
import { forgotPasswordSchema } from "../validations/forgotPasswordValidation";
import Form from "../components/form";

function ForgotPassword() {
  const [loginModel, setLoginModel] = useState<LoginModel>(new LoginModel());
  const [error, setError] = useState({ show: false, message: "" });
  const navigate = useNavigate();

  const submit = () => {
    forgotPasswordSchema
      .validate(loginModel)
      .then(() => {
        navigate("/passwordReset");
      })
      .catch((errResponse) => {
        setError({ show: true, message: errResponse.message });
      });
  };
  const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginModel({
      ...loginModel,
      userName: e.target.value,
    });
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isMailInValid =
    loginModel.userName !== "" && !emailRegex.test(loginModel.userName);
  return (
    <div className="h-screen bg-gray-50">
      <div className="h-full">
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="mx-auto h-auto w-auto" />
          </div>

          <div className=" white-box sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div>
                <h1 className="text-2xl font-bold mb-1">Forgot Password?</h1>
                <span className="block text-gray-500 mb-6">
                  Enter the email address associated with your account and we'll
                  send you a link to reset your password.
                </span>
                <Form  onSubmit={submit}>
                  <Input
                    placeholder="Enter your email"
                    label="Email"
                    onChange={onEmailInputChange}
                    errorMessage="Enter valid email"
                    type="email"
                    error={isMailInValid}
                    name="userName"
                    id="email"
                    required={true}
                    pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                  />
                  <div className="flex">
                    <Button
                      onClick={() => {
                        navigate("/passwordReset");
                      }}
                      className="text-sm px-5 py-2.5 mr-2 mb-2"
                      text="Send reset link"
                      type="primary"
                    />
                    <Button
                      onClick={() => {
                        navigate("/");
                      }}
                      className="!text-primary text-sm px-5 py-2.5 mr-2 mb-2 bg-white hover:bg-white shadow-none"
                      text="Cancel"
                    />
                  </div>
                </Form>
                
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
