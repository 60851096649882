interface Props{
    cardDetail: {name: string,
        description?: string,
        href: string,
        icon: any},
        onClick: (href:string) => void
}

export default function ProjectCard(props: Props) {

    return (
        <div className=" cursor-pointer max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-5 my-5" onClick={() => props.onClick(props.cardDetail.href)}>
            <div className="mx-10 mt-20">
                <props.cardDetail.icon className=" w-52 h-20 text-primary" aria-hidden="true"/>
            </div>
            <div className="p-5 flex items-center justify-center">
                <a className="mb-14 text-2xl font-bold text-gray-900 dark:text-white">
                    {props.cardDetail.name}
                </a>
                {props.cardDetail.description && <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{props.cardDetail.description}</p>}
            </div>
        </div>
    )
}