interface Props {
  children?: string,
  onChange?: any,
  className? : string,
  labelClassName?: string,
  checkBoxClassName?: string,
  value?: string,
  checked?: boolean,
}

function CheckBox(props?:Props) {

return (
  <div className={`${props?.className ?? ""} flex items-center`}>
    <input
      type="checkbox"
      defaultChecked={props?.checked}
      className={`${props?.checkBoxClassName ?? ""} h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary`}
      onChange={(event) => { 
         props?.onChange(event,props?.value)
      }}
    />
    <label className={`${props?.labelClassName ?? ""} ml-3 block text-sm leading-6 text-gray-900`}>
     {props?.children}
    </label>
  </div>
);

}
export default CheckBox;