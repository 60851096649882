import { useLoaderData, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import UserService from "../services/api/userService";
import { LoginModel } from "../model/loginModel";
import { useState, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getTokens, setTokens } from "../redux/tokenSlice";
import React from "react";
import { ReactComponent as Logo } from "../GravityLogoLg.svg";
import Button from "../components/button";
import CheckBox from "../components/checkBox";
import Input from "../components/input";
import { loginSchema } from "../validations/loginValidation";
import Form from "../components/form";
import { passwordValidation } from "../validations/passwordValidation";

function Login() {
  const tokens = useAppSelector((state) => state.tokens);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginModel, setLoginModel] = useState<LoginModel>(new LoginModel());
  const [errors, setErrors] = useState({
    userName: { show: false, message: "" },
    password: { show: false, message: "" },
    isShowing: false,
  });
  useEffect(() => {
    if (tokens.length > 1) {
      navigate("/gx");
    }
  }, [tokens]);

  const passwordInValidMsg = passwordValidation(loginModel.password);
  const signIn = () => {
    UserService.signIn(loginModel).then((res) => {
      //console.log('res',res);
      navigate("/gx");
      // UserService.test().then(res2 => {
      //   console.log('res2',res2)
      // })
    });

    // debugger;
    //   UserService.signIn(loginModel).then(res => {
    //     if(res === 200){
    //       return dispatch(setTokens())
    //     }
    //     else{
    //       setErrors({
    //         userName: {show: true, message: ""},
    //         password: {show: true, message: ""},
    //         isShowing: true
    //       })
    //     }
    //    }
    //   ).catch(res => {
    //     setErrors({
    //       userName: {show: true, message: ""},
    //       password: {show: true, message: ""},
    //       isShowing: true
    //     })
    // })

    // cookies.set('userTest',{ id : 1,firstName: "Dan", lastName: "Phung" })
  };
  const onSubmit = () => {
    loginSchema
      .validate(loginModel, { abortEarly: false })
      .then(() => {
        signIn();
      })
      .catch((errResponse: any) => {
        let errTemp = {
          userName: { show: false, message: "" },
          password: { show: false, message: "" },
        } as any;
        errResponse.inner.forEach((error: any) => {
          errTemp[error.path].message = error.message;
          errTemp[error.path].show = true;
        });
        setErrors(errTemp);
      });
  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginModel({
      ...loginModel,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="h-screen bg-gray-50 caret-transparent">
      <div className="h-full">
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="mx-auto h-auto w-auto" />
          </div>
          <div className="white-box sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className=" bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div className="space-y-6">
                <h1 className="font-bold text-xl flex justify-center">
                  Sign in to your account
                </h1>
                <Form onSubmit={onSubmit}>
                  {errors.isShowing && (
                    <label className="block text-sm font-medium leading-6 text-red-600">
                      Invalid email or password
                    </label>
                  )}
                  <Input
                    label="Email"
                    onChange={onInputChange}
                    placeholder="Enter your email"
                    errorMessage={errors.userName.message}
                    type="email"
                    error={errors.userName.show}
                    name="userName"
                    id="email"
                    required={true}
                    pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                  />
                  <Input
                    label="Password"
                    onChange={onInputChange}
                    placeholder="Enter your password"
                    type="password"
                    errorMessage={passwordInValidMsg}
                    error={passwordInValidMsg !== "" ? true : false}
                    name="password"
                    id="password"
                    required={true}
                  />
                  <div className="flex items-center justify-between">
                    <CheckBox onChange={(check: boolean, value: string) => {}}>
                      Remember me
                    </CheckBox>
                    <div className="text-sm leading-6">
                      <a
                        href="/forgotPassword"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <Button
                    type="primary"
                    className="flex w-full"
                    onClick={() => {}}
                    text="Sign in"
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
