export function passwordValidation(passwordInput:string) {
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{10,}/;
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const numberRegExp = /(?=.*?[0-9])/;
  const lowercasePassword = lowercaseRegExp.test(passwordInput);
  const specialCharPassword = specialCharRegExp.test(passwordInput);
  const minLengthPassword = minLengthRegExp.test(passwordInput);
  const uppercasePassword = uppercaseRegExp.test(passwordInput);
  const numberPassword = numberRegExp.test(passwordInput);
  if(passwordInput!==""){
    if (!lowercasePassword) {
      return "Include at least one lowercase character";
    } else if (!specialCharPassword) {
      return "Include at least one Special Character";
    } else if (!uppercasePassword) {
      return " Include at least one uppercase character";
    } else if (!numberPassword) {
      return "Include at least one number";
    } else if (!minLengthPassword) {
      return "Include at least 10 characters";
    } else return "";
  }else{
    return ""
  }
}