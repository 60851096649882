import { useState, useEffect } from "react";
import Input from "../components/input";
import Button from "../components/button";
import Form from "../components/form";
import Toggle from "../components/toggle";
import Checkbox from "../components/checkBox";
import Label from "../components/label";
import { useNavigate, useLocation } from "react-router-dom";
import { RoleModel } from "../model/roleModel";
import { UserModel } from "../model/userModel";
import { ApplicationModel } from "../model/applicationModel";
import UserService from "../services/api/userService";
import ClaimService from "../services/api/claimService";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { ClaimsModel } from "../model/claimsModel";

function AddUpdateUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [user, setUser] = useState<UserModel>(new UserModel());
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [claims, setClaims] = useState<ClaimsModel[]>([]);
  const [applications, setApplications] = useState<ApplicationModel[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        UserService.getAllRoles().then((rolesData) => {
          setRoles(rolesData);
        });
        //console.log("successGetRoles", roles)
      } catch (error) {
        //console.log("errorGetRoles", error);
      }
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        ClaimService.GetClaimsPresets().then((res) => {
          setApplications(res);
        });
        //console.log("successGetApplications", applications);
      } catch (error) {
        //console.log("errorGetApplications", applications);
      }
    };
    fetchApplications();
  }, []);

  useEffect(() => {
    if (state) {
      setIsEditing(true);
      setUser({
        ...state.user,
      });
      if (state.roles) {
        setSelectedRoles(state.roles);
      }
      if (state.claims) {
        setClaims(state.claims);
      }
    }
  }, []);

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    role: RoleModel
  ) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onCheckBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    role: RoleModel
  ) => {
    var roleEnum = user.role;
    if (role != null) {
      if (!e.target.checked && user.role & role.value) {
        roleEnum &= ~role.value;
      } else if (e.target.checked && !(user.role & role.value)) {
        roleEnum |= role.value;
      }
    }
  
    setUser({
      ...user,
      role: roleEnum,
    });
  };

  const onCheckBoxChangeAPP = (
    e: React.ChangeEvent<HTMLInputElement>,
    application: ApplicationModel
  ) => {
    const { checked } = e.target;

    if (!user.claims) {
      setUser((prevUser) => ({ ...prevUser, claims: [] })); 
      return; 
    }

    const existingClaimIndex = user.claims.findIndex(
      (claim) => claim.applicationId === application.id
    );

    if (checked) {
      if (existingClaimIndex === -1) {
        const newClaim: ClaimsModel = {
          userIdGuid: user.userIdentifier,
          applicationId: application.id,
          type: "App",
          value: application.name,
          active: true,
        };
        setUser((prevUser) => ({
          ...prevUser,
          claims: [...prevUser.claims, newClaim],
        }));
      } else {
        setUser((prevUser) => {
          const updatedClaims = [...prevUser.claims];
          updatedClaims[existingClaimIndex].active = true;
          return {
            ...prevUser,
            claims: updatedClaims,
          };
        });
      }
    } else {
      if (existingClaimIndex !== -1) {
        setUser((prevUser) => {
          const updatedClaims = [...prevUser.claims];
          updatedClaims[existingClaimIndex].active = false;
          return {
            ...prevUser,
            claims: updatedClaims,
          };
        });
      }
    }
  };

  const onToggleChange = (enabled: boolean) => {
    setUser({
      ...user,
      status: enabled,
    });
  };

  const userSubmit = () => {
    setIsLoading(true);
    if (state && user.id > 0) {
      UserService.editUser(user)
        .then((res) => {
          toastr.success("User updated successfully.");
        })
        .catch((errResponse) => {
          toastr.error("Failed to update user");
        })
        .finally(() => setIsLoading(false));
    } else {
      UserService.createUser(user)
        .then((res) => {
          toastr.success("User created successfully");
        })
        .catch((errResponse) => {
          toastr.error("Failed to create user");
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className="h-screen bg-gray-50 caret-transparent">
      <div className="flex min-h-screen flex-1 flex-col py-12 mx-auto max-w-3xl">
        <div className="grid grid-cols-4 gap-2">
          <div className="flex justify-start items-center col-span-3">
            {/* ADD AVATAR HERE */}
            <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-primary">
              <span className="text-sm font-medium leading-none text-white">
                {state && isEditing
                  ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName
                      .charAt(0)
                      .toUpperCase()}`
                  : ""}
              </span>
            </span>
            <div className="mx-4 text-gray-900 text-xl font-bold leading-normal">
              {isEditing ? "Edit User Account" : "New User Account"}
              <p className="font-normal text-sm text-gray-700">
                Fill in all required fields.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 bg-white p-4 shadow rounded-xl">
          <Form className="grid grid-cols-2 gap-8">
            <div className="space-y-6">
              <h1 className="font-bold text-xl">Basic Info</h1>
              <Input
                onChange={onInputChange}
                name="firstName"
                label="First Name"
                id="firstName"
                required={true}
                type="text"
                value={user.firstName}
              />
              <Input
                onChange={onInputChange}
                name="lastName"
                label="Last Name"
                id="lastName"
                required={true}
                type="text"
                value={user.lastName}
              />
              <Input
                onChange={onInputChange}
                name="email"
                label="Email"
                id="email"
                required={true}
                type="email"
                value={user.email}
              />
              <Input
                onChange={onInputChange}
                name="phoneNumber"
                label="Phone Number"
                id="phoneNumber"
                required={true}
                type="text"
                value={user.phoneNumber}
              />
            </div>

            <div className="space-y-5">
              <h1 className="font-bold text-xl">Account Settings</h1>
              <Label text="Account Status" />
              <Toggle
                enabled={user.status}
                onChange={(event) => onToggleChange(event)}
              ></Toggle>
              <Label text="Role" />
              <div className="">
                {roles &&
                  roles.map((role, index) => (
                    <Checkbox
                      key={index}
                      checked={
                        (role.value & user.role) === role.value &&
                        !(role.value === 0 && user.role > 0)
                      }
                      children={role.role}
                      value={role.value.toString()}
                      onChange={(event: any) => onCheckBoxChange(event, role)}
                    />
                  ))}
              </div>
              <Label text="Accessibility" />
              <div className="">
                {applications &&
                  applications.map((application, index) => {
                    const isChecked =
                      user.claims &&
                      user.claims.some(
                        (claim) =>
                          claim.applicationId === application.id && claim.active
                      );

                    return (
                      <Checkbox
                        key={index}
                        checked={isChecked}
                        children={application.name}
                        value={application.id.toString()}
                        onChange={(event: any) =>
                          onCheckBoxChangeAPP(event, application)
                        }
                      />
                    );
                  })}
              </div>
            </div>
          </Form>
          <div className="flex justify-end mt-4 space-x-4">
            <Button
              text={"Cancel"}
              type="secondary"
              onClick={() => {
                navigate("/gx/userManagement");
              }}
            />
            <Button
              text={isEditing ? "Update User" : "Create new user"}
              type="primary"
              onClick={userSubmit} // TODO:send password link on click
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUpdateUser;
