import { ReactComponent as Logo } from '../GravityLogoLg.svg';


function LoadingScreen() {
    return (
        <div className="grid h-screen place-items-center">
            <Logo className="mx-auto h-auto w-auto"/>
        </div>
    )
}

export default LoadingScreen