import { useState } from "react";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid"

interface Prop {
    error?: boolean,
    name?: string,
    id?: string,
    type?: string,
    required?: boolean,
    pattern?: string,
    errorMessage?: string,
    onChange?: any,
    label?: string,
    placeholder?: string,
    value?: string,
}

export default function Input (props:Prop) {
    const [showPassword, setShowPassword] = useState({show: false});
    
    return (
        <div className="relative container">
            <label htmlFor={props?.id} className={`${props?.error ?  "text-red-600": "text-gray-900"} block text-sm font-medium leading-6 `}>
                {props?.label}
            </label>
            <div className="mt-2">
                <input
                onChange={props?.onChange}
                id={props?.id}
                name={props?.name}
                type={showPassword.show ? 'text' : props?.type}
                autoComplete="on"
                required={props?.required}
                pattern={props?.pattern}
                placeholder={props?.placeholder}
                value={props?.value}
                className={`${props?.error ?  "!bg-red-50 !ring-red-500": "bg-gray-50" } caret-current block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6`}
                />
                {props?.type === "password" && (showPassword.show ?
                    <EyeIcon className="cursor-pointer absolute inset-y-10 right-0 flex items-center px-4 text-gray-600 w-12 h-6"
                    onClick={() => {setShowPassword({show:false})}}
                    />
                    :
                    <EyeSlashIcon className="cursor-pointer absolute inset-y-10 right-0 flex items-center px-4 text-gray-600 w-12 h-6"
                    onClick={() => {setShowPassword({show:true})}}
                    />)
                }
            </div>
            {props?.error && <label className="block text-sm font-medium leading-6 text-red-600">{props?.errorMessage}</label>}
        </div>
    )
}