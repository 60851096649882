import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import UserService from "../services/api/userService";
import { UserModel } from "../model/userModel";
import { RoleModel, RoleEnum } from "../model/roleModel";
import { PagingModel } from "../model/pagingModel";

function UserManagement(props: any) {
  const navigate = useNavigate();

  const [pagingInfo, setPagingInfo] = useState<PagingModel>({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    pageSize: 20, 
  });

  const [users, setUsers] = useState<[UserModel]>();
  const [ timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [roles, setRoles] = useState<RoleModel[]>([]);

  useEffect(() => {
    fetchUsers();
  }, [pagingInfo.currentPage]);

  const fetchUsers = async () => {
    try {
       UserService.getAllUsers(pagingInfo.currentPage, pagingInfo.pageSize).then((usersData) => {
        setUsers(usersData.data);
        setPagingInfo({
          currentPage: usersData.currentPage,
          totalPages: Math.ceil(usersData.totalItems / pagingInfo.pageSize),
          totalItems: usersData.totalItems,
          pageSize: usersData.pageSize,
        });
      });
      UserService.getAllRoles().then((rolesData) => {
        setRoles(rolesData);
      });
      //console.log("success", users);
    } catch (error) {
      //console.log("error", error);
    }
  };

  const goToPreviousPage = () => {
    if (pagingInfo.currentPage > 1) {
      setPagingInfo((prevPagingInfo) => ({
        ...prevPagingInfo,
        currentPage: prevPagingInfo.currentPage - 1,
      }));
    }
  };

  const goToNextPage = () => {
    if (pagingInfo.currentPage < pagingInfo.totalPages) {
      setPagingInfo((prevPagingInfo) => ({
        ...prevPagingInfo,
        currentPage: prevPagingInfo.currentPage + 1,
      }));
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(timeoutId){
      clearTimeout(timeoutId)
    }
    const searchQuery = e.target.value;
    const currentTimeOutId = setTimeout(() => {
      UserService.getAllUsers(1, pagingInfo.pageSize, searchQuery).then((usersData) => {
        setUsers(usersData.data);
        setPagingInfo({
          currentPage: 1, // Reset current page to 1
          totalPages: Math.ceil(usersData.totalItems / pagingInfo.pageSize), // Update total pages based on filtered data
          totalItems: usersData.totalItems,
          pageSize: pagingInfo.pageSize,
        });
      });
    }, 500);
    setTimeoutId(currentTimeOutId);
  }

  const convertRole = (value:RoleEnum) =>{

    const roleName = roles.filter(x => (value & x.value) !== 0)
    const stringRole = roleName.map(x => x.role).join(', ');
    const temp = `${roleName.length} Roles`
    if(!stringRole){
      return "None";
    }
    return roleName.length > 2 ? temp : stringRole;
  }

  const convertExpandRole = (value:RoleEnum) =>{

    const roleName = roles.filter(x => (value & x.value) !== 0)
    const stringRole = roleName.map(x => x.role).join(', ');
    if (roleName.length < 3)
    {
      setHoveredRowIndex(null)
    }
    return stringRole;
  }

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 bg-[#f9fafb] h-full">
        <div className="sm:flex sm:items-center py-5">
          <div className="sm:flex-auto flex-col">
            <div className="text-gray-900 text-2xl font-bold  leading-9">
              User Management
            </div>
            <p className="text-sm text-gray-700">
              Select a user to edit, or add a new user.
            </p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md">
          <div className="w-full py-5 px-5 bg-white rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2.5 rounded-lg">
            {/* Search Bar */}
            <div className="flex-1 ">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  className="px-4 py-2 w-96 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            {/* New User Button */}
            <div className="flex-1 flex justify-end">
              <Button
                onClick={() => {
                  navigate("/user");
                }}
                type="primary"
                text="New user account"
              />
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50 ">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-xs uppercase text-gray-500 font-semibold sm:pl-6"
                >
                  First Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-xs uppercase text-gray-500 font-semibold"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-xs uppercase text-gray-500 font-semibold"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-xs uppercase text-gray-500 font-semibold"
                >
                  Status
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody className="">
              {users &&
                users.map((user, index) => (
                  <tr key={user.id}>
                    <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-gray-900">
                      {user.firstName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {user.lastName}
                    </td>
                     <td
                className="relative whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredRowIndex === index && (
                  <div key={user.id} className="absolute z-10 px-4 py-2 text-xs text-white bg-gray-800 rounded-md shadow-lg whitespace-normal break-words">
                    {convertExpandRole(user.role)}
                  </div>
                )}
                {convertRole(user.role)}
                
              </td>
                    <td
                      className={`whitespace-nowrap px-3 py-4 text-sm  ${
                        user.status ? "text-green-600 font-medium" : "text-gray-900"
                      }`}
                    >
                      {user.status ? "Active" : "Inactive"}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Button type="primary" text="Manage" onClick={() => navigate("/user", {state: {user}})}/>
                    </td>
                  </tr>
                ))}

              {!users && (
                <tr>
                <td
                  colSpan={5}
                  className="text-center items-center py-5"
                >
                  There are no records to display
                </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between pb-4 mt-3">
          <span className="flex">
          Page {pagingInfo.currentPage} of {pagingInfo.totalPages}
          </span>
          <div className="flex">
            <Button
              type="secondary"
              text="Previous"
              disabled={pagingInfo.currentPage === 1}
              onClick={goToPreviousPage}
            />
            <Button
              type="secondary"
              text="Next"
              disabled={pagingInfo.currentPage === pagingInfo.totalPages}
              onClick={goToNextPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManagement;
