import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { LoginModel } from '../model/loginModel'
import Cookies from 'universal-cookie';
import { TokenModel } from '../model/tokenModel';
import UserService from '../services/api/userService';

const initialState: TokenModel[] = [];
const cookies = new Cookies();

export const tokenSlice = createSlice({
  name: 'token',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTokens: (state) => {
      const rtCookie = cookies.get("rt")
      if(!rtCookie){
        return state;
      }
      const rtBase64Url = rtCookie.split('.')[1];
      const rtBase64 = rtBase64Url.replace(/-/g, '+').replace(/_/g, '/');
      let rtJsonPayload = decodeURIComponent(window.atob(rtBase64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      let rt = JSON.parse(rtJsonPayload) as TokenModel
      
      const atCookie = cookies.get("at")
      if(!atCookie){
        return state;
      }
        var atBase64Url = cookies.get("at").split('.')[1];
        var atBase64 = atBase64Url.replace(/-/g, '+').replace(/_/g, '/');
        var atJsonPayload = decodeURIComponent(window.atob(atBase64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let at = JSON.parse(atJsonPayload) as TokenModel
      return [rt,at];
    },
    checkTokens: (state,action: PayloadAction<TokenModel[]>) => {
      const tokens = action.payload;
      if(tokens.length  > 0){
        const rtToken = tokens.filter(x => x.Refresh === "1")[0];
        const currentDate = new Date();
        const rtDate = new Date(rtToken.exp * 1000);
        if(currentDate > rtDate){
            cookies.remove("rt");
            cookies.remove("at");
          //  navigate("/")
        }
    }
      // const proxy = new Proxy([1, 2, 3], {
      //   get(target, prop, receiver) {
      //     return target;
      //   }
      // });
      
      //console.log([...proxy]);
    }
  },
})

export const { setTokens,checkTokens } = tokenSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getTokens = (state: RootState) => state.tokens

export default tokenSlice.reducer