import { LoginModel } from "../model/loginModel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import Input from "../components/input";
import { updatePasswordSchema } from "../validations/updatePasswordValidation";
import Form from "../components/form";
import { ReactComponent as Logo } from "../GravityLogoLg.svg";
import { passwordValidation } from "../validations/passwordValidation";
function UpdatePassword() {
  const [loginModel, setloginModel] = useState<LoginModel>(new LoginModel());
  const [error, setError] = useState({ show: false, message: "" });
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const submit = () => {
    updatePasswordSchema
      .validate(loginModel)
      .then(() => {
        navigate("/");
      })
      .catch((errResponse: { message: any }) => {
        setError({ show: true, message: errResponse.message });
      });
  };
  const onPasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setloginModel({
      ...loginModel,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };
  const passwordInValidMsg = passwordValidation(loginModel.password);
  const isConfirmPasswordInvalid =
    confirmPassword !== "" &&
    loginModel.password !== confirmPassword
      ? true
      : false;
  return (
    <div className="h-screen bg-gray-50">
      <div className="h-full">
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="mx-auto h-auto w-auto" />
          </div>
          <div className=" white-box sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <h1 className="text-2xl font-bold mb-1">Reset Password</h1>
              <span className="block text-gray-500 mb-6">
                Enter your new password below
              </span>
              <Form onSubmit={submit}>
                <Input
                  error={passwordInValidMsg !== "" ? true : false}
                  errorMessage={passwordInValidMsg}
                  onChange={onPasswordInputChange}
                  type="password"
                  name="password"
                  id="password"
                  label="New Password"
                  required={true}
                  placeholder="Enter your new password"
                />
                <Input
                  error={isConfirmPasswordInvalid}
                  errorMessage="Both passwords must match"
                  onChange={onPasswordInputChange}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                  required={true}
                  placeholder="Confirm your password"
                />
                <div>
                  <Button
                    type="primary"
                    className="flex w-full"
                    onClick={() => submit()}
                    text="Reset Password"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
