import { RoleEnum } from "./roleModel";
import { ClaimsModel } from "./claimsModel";

export class UserModel  {
    id: number = 0;
    userIdentifier: string = "";
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    phoneNumber: string = "";
    role: RoleEnum = 0;
    status: boolean = false;
    claims: ClaimsModel[] = [];
   }

