import BaseService from '../baseService';
import { ClaimsModel } from '../../model/claimsModel';

const authUrl = process.env.REACT_APP_API_URL;

const GetClaimsPresets = async () => {
    return await BaseService.get(authUrl + "/claim").then(res => {
        //console.log("claims service", res)
       return res.data;
    })
  }

const ClaimService = {
    GetClaimsPresets
}

 export default ClaimService;