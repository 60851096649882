export class RoleModel {
  public role: string;
  public value: RoleEnum;   
  constructor(role: string, value: RoleEnum) {
      this.role = role;
      this.value = value;
  
}
};


export enum RoleEnum {
  None = 0,
  Customer = 1,
  Cashier = 2,
  VenueManager = 4,
  Accountant = 8,
  Admin = 16,
  ContentManager = 32
} 