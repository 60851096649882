
import Cookies from 'universal-cookie';
import axios, { AxiosResponse } from "axios";
import {ResponseModel} from "../model/responseModel";

/**
 * @description Represents an instance of Axios with configured options for making HTTP requests.
 * @type {AxiosInstance}
 */
const HTTP = axios.create({
    withCredentials: true,
});
/**
 * The URL for authentication.
 *
 * @type {string | undefined}
 * @default process.env.REACT_APP_API_URL
 */
const authUrl :string | undefined  = process.env.REACT_APP_API_URL;
/**
 * Represents the audience for authentication.
 *
 * @type {string}
 * @default process.env.REACT_APP_AUDIENCE || "AuthenticationAPI"
 */
const authAudience : string =  process.env.REACT_APP_AUDIENCE || "AuthenticationAPI";
/**
 * Class representing Cookies.
 * @class
 * @classdesc This class provides cookie management functionality.
 */
const cookies = new Cookies();

/**
 * Asynchronously checks the given AxiosResponse for errors and returns a ResponseModel.
 *
 * @param {AxiosResponse} response - The axios response object to check for errors.
 * @returns {Promise<ResponseModel>} - A promise that resolves with the ResponseModel.
 */
const checkError = async (response:AxiosResponse):Promise<ResponseModel> => {
        const responseModel = new ResponseModel();
        responseModel.status = response.status;
        responseModel.data = await response.data.json();
        // TODO: handle error by showing toast
        return responseModel;
};
/**
 * Creates a ResponseModel object using the provided AxiosResponse object.
 *
 * @param {AxiosResponse} response - The AxiosResponse object containing the response data.
 * @returns {Promise<ResponseModel>} - A Promise that resolves with the ResponseModel object.
 */
const checkResponse = async (response:AxiosResponse):Promise<ResponseModel> => {
    const responseModel = new ResponseModel();
    responseModel.status = response.status;
    responseModel.data = response.data;
    return responseModel;
}

/**
 * This function is used to make an anonymous post request.
 *
 * @param {string} url - The URL endpoint to make the post request to.
 * @param {Object} params - Optional parameters that can be passed with the request.
 *
 * @returns {Promise} - The promise that resolves with the result of the post request.
 */
const postAnonymous = async(url: string, params?:any) => {
    return await HTTP.post(url, params).then(checkResponse).catch(checkError);
 }

/**
 * Retrieves data from the specified URL using an HTTP GET request.
 *
 * @param {string} url - The URL of the resource to retrieve.
 * @return {Promise} - A Promise that resolves with the retrieved data, or rejects with an error.
 *
 * @async
 * @function getAnonymous
 */
const getAnonymous = async (url: string) => {
    return await HTTP.get(url).then(checkResponse).catch(checkError);
};

/**
 * Send a HTTP POST request to the specified URL with the provided body.
 *
 * @param {string} url - The URL to send the request to.
 * @param {any} body - The body of the request.
 * @param {string} [audience=authAudience] - The audience for the API setup.
 * @returns {Promise<ResponseModel>} - A Promise that resolves to a ResponseModel object.
 */
const post = async (url:string, body:any, audience:string = authAudience):Promise<ResponseModel> => {
    const code: number = await setUpAPI(audience);
    if (code !== 200) {
        const responseModel = new ResponseModel();
        responseModel.status = 401;
    }

    return await HTTP.post(url, body).then(checkResponse).catch(checkError);
};

/**
 * Sends a GET request to the specified URL.
 *
 * @param {string} url - The URL to send the GET request to.
 * @param {string} [audience=authAudience] - The audience for authentication.
 * @returns {Promise<ResponseModel>} - A promise that resolves to a ResponseModel object.
 */
export const get = async (url:string, audience:string = authAudience):Promise<ResponseModel> => {
    const code:number = await setUpAPI(audience);
    if (code !== 200) {
        const responseModel = new ResponseModel();
        responseModel.status = 401;
    }
    return await HTTP.get(url).then(checkResponse).catch(checkError);
};


/**
 * Sets up the API by checking if the user information and access token are valid.
 * If they are not valid or expired, it retrieves new access token.
 *
 * @async
 * @param {string} [audience=authAudience] - The audience for the access token.
 * @returns {Promise<number>} - The HTTP status code indicating the result of the setup process.
 *  - 200: Success. The API has been set up successfully.
 *  - 401: Unauthorized. The user needs to reauthenticate or provide valid credentials.
 */
const setUpAPI = async (audience = authAudience) => {
    const info = await cookies.get("uinfo");
    let ainfo = await cookies.get("ainfo");
    if (new Date(info.expDateTime) < new Date()) {
        //needs a new refresh token
        return 401;
    } else {
        if (!ainfo || new Date(ainfo.expDateTime) < new Date() || audience !== ainfo.audience) {
            await getAccessToken(audience).then((res) => {
                if (res === 200) {
                    return 200;
                }
            });
        }
        ainfo = await cookies.get("ainfo");
        if (!ainfo || new Date(ainfo.expDateTime) < new Date() || audience !== ainfo.audience) {
            //needs a new refresh token
            return 401;
        }
        return 200;
    }
};
/**
 * Retrieves an access token from a specified authentication URL
 *
 * @function getAccessToken
 * @async
 * @param {string} [audience=authAudience] - The audience for the access token
 * @returns {Promise<number>} - A promise that resolves with the status code of the access token retrieval
 * @throws {Error} - If an error occurred during the access token retrieval
 */
export const getAccessToken = async (audience = authAudience) => {
    const url = authUrl + "/authentication/access";
    return await HTTP.get(url, { params: { audience } }).then(async (result) => {
        if (result.status === 200) {
            cookies.remove("ainfo");
            await cookies.set("ainfo", result.data);
        }
        return result.status;
    }).catch((err) => {
        return err.status
    });
};


/**
 * BaseService represents a basic service for making HTTP requests.
 *
 * @namespace
 */
const  BaseService = {
        postAnonymous,
        getAnonymous,
        get,
        post
}


 export default BaseService;