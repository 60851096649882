
import { useState } from "react";

interface Props {
    data?: string[],
    onClick?: (name:string) => void;
    onChange?: (name:string) => void;
}

  export default function Tabs(props:Props) { 
    const [tabs,setTabs] = useState(() => {
        if(!props.data){
            return [{name:"NONE",current: true}];
        }

       let initTabs = props.data.map(name => { return {  name , current : false} })
       initTabs[0].current = true;
       
        return initTabs
    });
    const tabsOnClick = (name:string) => {
            setTabs((prev) => {
                if(prev){
                   prev.forEach(x => x.name === name ?  x.current = true : x.current = false)       
                   return [...prev!];        
                }
                return prev;
            })
            if(props.onClick){
                props.onClick(name);
            }
    }

    const tabsOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      let name = e.target.value
      setTabs((prev) => {
        if(prev){
           prev.forEach(x => x.name === name ?  x.current = true : x.current = false)       
           return [...prev!];        
        }
        return prev;
        })
        if(props.onClick){
            props.onClick(name);
        }
      }


    return (
      <div className="justify-center">
        <div className="sm:hidden mt-3">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs?.find((tab) => tab.current)?.name}
            onChange={(e) => tabsOnChange(e)}
          >
            {tabs?.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs?.map((tab) => (
                <a
                  onClick={() => tabsOnClick(tab.name)}
                  key={tab.name}
                  className={tab.current 
                    ? 'border-primary text-primaryHover whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-default'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                  }
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
  }
  