import * as Yup from "yup";

const email = Yup.object().shape({
    current: Yup.string().email("Enter a valid email").required("Current email is required"),
    new: Yup.string().email("Enter a valid email").required("New email is required").notOneOf([Yup.ref('current')], 'Email must not be same as current one'),
    confirm: Yup.string().email("Enter a valid email").required("Must confirm new email").oneOf([Yup.ref('new')], 'Emails must match')
})

const password = Yup.object().shape({
    current: Yup.string().required("Current password is required"),
    new: Yup.string().required("New password is required")
    .min(10, 'Password must be at least 10 characters')
    .max(100, 'Password must be at most 100 characters')
    .notOneOf([Yup.ref('current')], 'Password must not be same as current one')
    .matches(/[A-Z]/,"New password must contain at least one upper case letter")
    .matches(/[a-z]/,"New password must contain at least one lower case letter")
    .matches(/\W/,"New password must contain at least one special character"),
    confirm: Yup.string().required("Must confirm new password").oneOf([Yup.ref('new')], 'Passwords must match')
})

const accountSettingsValidation = {email,password}

export default accountSettingsValidation;