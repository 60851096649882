import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import  Login  from './layouts/login';
import { Provider } from 'react-redux'
import { store } from './redux/store'

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NoPage from './layouts/no-page';
import Home from './layouts/home';
import UserService from './services/api/userService';
import UpdatePassword from './layouts/updatePassword';
import ForgotPassword from "./layouts/forgotPassword"
import UserManagement from './layouts/userManagement';
import AccountSettings from './layouts/accountSettings';
import AddUpdateUser from './layouts/addUpdateUser';
import LoadingScreen from './components/loadingScreen';
import NavBar from './components/navBar/navBar';
import { Helmet } from 'react-helmet';
import favicon from '../src/assets/images/Logo.png';

const Main = React.lazy(()=> import('./layouts/main'))

const router = createBrowserRouter([
  {
    path: "/",
    element:<Login/>,
    errorElement: <NoPage/>,
    
  },
  {
    path: "/gx",
    element:<React.Suspense fallback={<LoadingScreen/>}><Main/></React.Suspense>,
  //  errorElement: <NoPage/>,
    loader: async () =>  {return 1},
    children: [
      {     
        index: true,
        path: "",
        element: <Home/>,
      },
      {
        path: "userManagement",
        element: <UserManagement/>,
        errorElement: <NoPage/>
      },
      {
        path: "accountSettings",
        element: <AccountSettings/>,
        errorElement: <NoPage/>
      }
    ]
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword/>,
    errorElement: <NoPage/>,
  },
  {
    path: "/passwordReset",
    element: <UpdatePassword/>,
    errorElement: <NoPage/>,
  },
  {
    path: "/user",
    element: <AddUpdateUser/>,
    errorElement: <NoPage/>
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* {true && <NavBar />} */}
      <RouterProvider router={router} />
   </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
